import data from '../../data.json'

const modalsOverlay = document.querySelector('.modals')

modalsOverlay.addEventListener('click', e => {
  if (e.target.matches('.modals')) {
    document.body.style.overflow = 'auto'
    modalsOverlay.classList.remove('is-visible')
    document.querySelectorAll('.modal').forEach(modal => {
      modal.classList.remove('is-visible')
    })
  }
})

const openModal = modal => {
  document.body.style.overflow = 'hidden'
  modalsOverlay.classList.add('is-visible')
  modalsOverlay.querySelector(modal).classList.add('is-visible')
}

const offerTargets = document.querySelectorAll('a[href="#offer"]')
offerTargets.forEach(offerTarget => {
  offerTarget.addEventListener('click', e => {
    e.preventDefault()
    openModal('.offer')
  })
})

const targetCompanyEl = document.querySelector('input[name="target_company"')

addEventListener('click', e => {
  if (!e.target.closest('#rating button, .company-modal .button')) return
  closeModals()
  openModal('.lead')
  const leadTarget = e.target.closest('#rating button, .company-modal .button')
  targetCompanyEl.value = leadTarget.getAttribute('data-company')
})


const closeModals = () => {
  const modals = document.querySelectorAll('.modal')
  modals.forEach(modal => modal.classList.remove('is-visible'))

  document.querySelector('.modals').classList.remove('is-visible')
  document.body.style.overflow = 'auto'
}

document.querySelectorAll('.close-modals').forEach(btn => {
  btn.addEventListener('click', () => closeModals())
})



addEventListener('click', e => {
  if (e.target.closest('.company')) {
    const companyRow = e.target.closest('.company-row')
    const index = parseInt(companyRow.getAttribute('data-index'))

    openModal('.company-modal')

    document.querySelector('.company-modal-text').innerHTML = data[index]
    document.querySelector('.company-modal-image').src = companyRow.querySelector('.company-image').src
    document.querySelector('.company-modal-rating').innerHTML = companyRow.querySelector('.company-rating').innerText
    document.querySelector('.company-modal-name').innerHTML = companyRow.querySelector('.company-name').innerText
    document.querySelector('.company-modal-likes').innerHTML = companyRow.querySelector('.company-likes').innerText
    document.querySelector('.company-modal-dislikes').innerHTML = companyRow.querySelector('.company-dislikes').innerText
    document.querySelector('.company-modal-rates').innerHTML = parseInt(companyRow.querySelector('.company-likes').innerText) + parseInt(companyRow.querySelector('.company-dislikes').innerText)
    document.querySelector('.company-modal-mouth-rates').innerHTML = Math.ceil((30 - index) * 1.24)
    document.querySelector('.company-modal .button').setAttribute('data-company', companyRow.querySelector('#rating button').getAttribute('data-company'))

    document.querySelector('.modal-mark').innerText = 'ТОП #' + (index+1)

    const rewards = document.querySelectorAll('.modal-reward-1, .modal-reward-2, .modal-reward-3')
    rewards.forEach(reward => reward.classList.add('hidden'))
    if (index === 0) {
      rewards[0].classList.remove('hidden')
    }
    if (index === 1) {
      rewards[1].classList.remove('hidden')
    }
    if (index === 2) {
      rewards[2].classList.remove('hidden')
    }
  }
})

document.querySelector('.calculationg-modal-link').addEventListener('click', e => {
  closeModals()
  document.querySelector("#calculating").scrollIntoView()
})

addEventListener('click', (e) => {
  if (!e.target.closest('.company-rating')) return

  openModal('.rating-modal')
})

addEventListener('click', (e) => {
  if (!e.target.closest('.company-likes, .company-dislikes')) return

  openModal('.reviews-modal')
})

