[
  "Крупнейшая ремонтная компания, которая специализируется на разработке дизайн-проекта для интерьера и его реализации. Компания предоставляет полный спектр услуг от проектного планирования до финишной отделки. <br>Компания на рынке с 2011 года реализовали свыше 1000 дизайн-проектов и 500 ремонтов. В штате более 15 дизайнеров и 400 узкопрофильных специалистов по ремонту.",
  "Ведущая ремонтная компания в России с крупнейшим собственным складом оптовых материалов. На рынке более 7 лет, специализирующаяся на предоставлении услуг по ремонту и отделке квартир, домов, офисов и других коммерческих помещений. В штате свыше 10 дизайнеров и более 300 узкопрофильных специалистов по ремонту",
  "Официальный партнер Knauf по квалификации специалистов по ремонту. Компания специализируется на ремонте квартир. За 8 лет работы, реализовали свыше 300 проектов под ключ. <br> Компания может также оказать помощь в приеме квартиры от застройщика и в разработке инженерных решений, включая систему “Умный дом”",
  "Компания специализируется на ремонте и дизайне жилых помещений. На рынке более 7 лет, реализовали свыше 1900 ремонтов. В штате 450 специалистов по ремонту. В дополнительные услуги компании входит разработка инженерных решений, включая систему 'Умный дом'",
  "Группа компаний Ремонт-Экспресс существует на рынке с 2012 года. Специализируются на внутренней отделке помещений различного назначения. Выполняют все виды ремонта от косметики комнат, до капитального ремонта квартир, офисов и коттеджей.",
  "Компания работает с 2018 года и специализируется на ремонте под ключ, начиная с дизайн-проекта и заканчивая подбором мебели. В штате 187 специалистов",
  "Компания работает на рынке ремонтных услуг уже 5 лет. У них работает более 20 высококвалифицированных специалистов-ремонтников, а также несколько специалистов по дизайну.",
  "Компания Аваль Ремонт работает на рынке ремонтных услуг уже более 10 лет. У них трудится более 40 специалистов по ремонту, а также несколько профессионалов по дизайну.",
  "Компания работает на рынке ремонтных услуг более 7 лет. В ее состав входит более 30 специалистов-ремонтников, а также несколько профессионалов по дизайну интерьера.",
  "Компания работает на рынке ремонтных услуг уже более 15 лет. У них трудятся более 50 специалистов-ремонтников и дизайнеров интерьера.",
  "Компания уже более 8 лет работает на рынке ремонтных услуг. В штате компании более 25 опытных работников, включая специалистов по дизайну интерьера.",
  "Компания работает на рынке ремонтных услуг уже более 12 лет. В ее состав входит более 30 специалистов-ремонтников и 5 дизайнеров интерьера.",
  "Компания работает на рынке ремонтных услуг уже более 10 лет. В компании более 25 специалистов-ремонтников и 3 дизайнера интерьера.",
  "Компания уже более 5 лет работает на рынке ремонтных услуг. У них трудятся более 20 специалистов-ремонтников и 2 дизайнера интерьера",
  "Компания 10 лет на рынке, 15 специалистов в штате, более 100 выполненных проектов. Компания специализируется на ремонте квартир и офисов любой сложности.",
  "Компания 5 лет на рынке, 10 специалистов в штате, более 70 выполненных проектов. Компания предлагает комплексные услуги по ремонту квартир и домов.",
  "Компания 7 лет на рынке, 20 специалистов в штате, более 150 выполненных проектов. Компания занимается ремонтом квартир, дач и загородных домов, а также строительством домов \"под ключ\".",
  "Компания 3 года на рынке, 5 специалистов в штате, более 100 выполненных проектов. Компания специализируется на ремонте квартир, проводит работы любой сложности.",
  "Компания 8 лет на рынке, 12 специалистов в штате, более 200 выполненных проектов. Компания предлагает качественный ремонт квартир и дач по доступным ценам.",
  "Компания 6 лет на рынке, 8 специалистов в штате, более 120 выполненных проектов. Компания проводит ремонт как вторичного жилья, так и новостроек.",
  "Компания 12 лет на рынке, 30 специалистов в штате, более 100 выполненных проектов. Компания занимается ремонтом квартир, строительством домов и дач.",
  "Компания 4 года на рынке, 6 специалистов в штате, более 150 выполненных проектов. Компания предлагает качественный и быстрый ремонт квартир любой сложности.",
  "Компания 9 лет на рынке, 18 специалистов в штате, более 200 выполненных проектов. Компания проводит качественный ремонт квартир, офисов и домов \"под ключ\".",
  "Компания 2 года на рынке, 4 специалиста в штате, более 50 выполненных проектов. Компания специализируется на ремонте квартир в новостройках.",
  "Компания 11 лет на рынке, 25 специалистов в штате, более 170 выполненных проектов. Компания занимается ремонтом квартир, офисов и домов. Также предлагает услуги по дизайну интерьера.",
  "Компания 6 лет на рынке, 10 специалистов в штате, более 50 выполненных проектов. Компания проводит ремонт квартир и домов любой сложности.",
  "Компания 4 года на рынке, 6 специалистов в штате, более 60 выполненных проектов. Компания занимается ремонтом квартир в новостройках и вторичного жилья.",
  "Компания 8 лет на рынке, 15 специалистов в штате, более 200 выполненных проектов. Компания предлагает качественный и быстрый ремонт квартир и офисов.",
  "Компания 5 лет на рынке, 9 специалистов в штате, более 100 выполненных проектов. Компания занимается ремонтом квартир и домов \"под ключ\".",
  "Компания 7 лет на рынке, 12 специалистов в штате, более 50 выполненных проектов. Компания проводит ремонт квартир любой сложности."
]