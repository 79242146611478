const calculation = document.querySelector('.calculation')

const btn = calculation.querySelector('.button')

const send = formData => {
  console.log(formData)
  const url = 'https://remratemsk.ru/Backend/send.php'

  // add utm tags to formData from uri
  const utmTags = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content'
  ]
  utmTags.forEach(tag => {
    const tagValue = new URLSearchParams(window.location.search).get(tag)
    if (tagValue) {
      formData[tag] = tagValue
    }
  })

  const data = JSON.stringify(formData)

  document.querySelectorAll('.modal').forEach(el => {
    el.classList.remove('is-visible')
  })

  const modalOverlay = document.querySelector('.modals')

  const doneModal = document.querySelector('.done')
  modalOverlay.classList.add('is-visible')

  document.body.style.overflow = 'hidden'

  doneModal.classList.add('is-visible')

  fetch(url, {
    method: 'POST',
    cors: 'no-cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
    .then(response => response.json())
    .then(data => {
      setTimeout(() => {
        doneModal.classList.remove('is-visible')
        modalOverlay.classList.remove('is-visible')
        document.body.style.overflow = ''
      }, 1 * 5000)
    })
}

btn.addEventListener('click', () => {
  const formData = {}

  formData['form'] = 'расчет стоимости ремонта'

  if (window.matchMedia('(max-width: 575px)').matches) {
    formData['object'] = calculation.querySelector(
      'select[data-type="obj"]'
    ).value
    formData['type'] = calculation.querySelector(
      'select[data-type="type"]'
    ).value
  } else {
    formData['object'] = calculation
      .querySelector('button.is-active[data-type="obj"]')
      .getAttribute('data-value')
    formData['type'] = calculation
      .querySelector('button.is-active[data-type="type"]')
      .getAttribute('data-value')
  }

  formData['area'] = calculation.querySelector('.range-input').value
  const phoneEl = calculation.querySelector('input[type="tel"]')
  formData['phone'] = phoneEl.value

  if (formData['phone'].length < 17) {
    phoneEl.style.borderColor = 'red'
    return
  }
  phoneEl.style.borderColor = ''
  ym(93078808, 'reachGoal', 'calc-down')
  send(formData)
})

const lead = document.querySelector('.lead')

const btnLead = lead.querySelector('.button')

btnLead.addEventListener('click', () => {
  const formData = {}

  formData['form'] = 'отклик по ремонтной компании'
  formData['company'] = lead.querySelector('input[name="target_company"]').value
  formData['type'] = lead.querySelector('select[name="type"]').value
  formData['object'] = lead.querySelector('select[name="obj"]').value
  formData['area'] = lead.querySelector('input[name="area"]').value

  formData['gifts'] = [...lead.querySelectorAll('input[name="gift"]:checked')]
    .map(el => el.closest('label').innerText)
    .join(', ')

  const phoneEl = lead.querySelector('input[type="tel"]')
  formData['phone'] = phoneEl.value

  if (formData['phone'].length < 17) {
    phoneEl.style.borderColor = 'red'
    return
  }
  phoneEl.style.borderColor = ''

  ym(93078808, 'reachGoal', 'lead-table')
  send(formData)
})

const offer = document.querySelector('.offer')

const btnOffer = offer.querySelector('.button')

btnOffer.addEventListener('click', () => {
  const formData = {}

  formData['company'] = offer.querySelector('input[name="company"]').value

  if (formData['company'].length < 1) {
    offer.querySelector('input[name="company"]').style.borderColor = 'red'
    return
  }
  offer.querySelector('input[name="company"]').style.borderColor = ''

  formData['form'] = 'заявка на новую компанию'

  send(formData)
})
