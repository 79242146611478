const range = document.querySelector('.range')
const rangeThumb = document.querySelector('.range-thumb')
const rangeInput = document.querySelector('.range-input')
const rangeLeft = document.querySelector('.range-left')

range.addEventListener('input', e => {
  const min = range.min
  const max = range.max

  const value = e.target.value
  const percent = ((value - min) / (max - min)) * 100
  rangeLeft.style.width = `${percent - (percent / 100) * 4.2}%`
  rangeThumb.style.left = `${percent - (percent / 100) * 4.2}%`

  rangeInput.value = value
})

rangeInput.addEventListener('input', e => {
  const min = parseInt(range.min)
  const max = parseInt(range.max)

  let value = parseInt(e.target.value)

  if (value < min) value = min
  else if (value > max) value = max
  else if (isNaN(value)) value = min

  range.value = value

  const percent = ((value - min) / (max - min)) * 100
  rangeLeft.style.width = `${percent - (percent / 100) * 4.2}%`
  rangeThumb.style.left = `${percent - (percent / 100) * 4.2}%`

  rangeInput.value = value
})
