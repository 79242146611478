const selectors = document.querySelectorAll('.selector')

const selectorData = {}

const changeSelectorData = (key, value) => {
  selectorData[key] = value
}

selectors.forEach(selector => {
  const selectorElems = selector.querySelectorAll('.selector-elem')
  selectorElems.forEach(elem => {
    elem.addEventListener('click', e => {
      const key = elem.getAttribute('data-type')
      const value = elem.getAttribute('data-value')
      changeSelectorData(key, value)

      selectorElems.forEach(elem => {
        elem.classList.remove('is-active')
      })
      elem.classList.add('is-active')
    })
  })
})
