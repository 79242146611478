document.querySelectorAll('input[type="tel"]').forEach(input => {
  let keyCode

  const mask = event => {
    event.keyCode && (keyCode = event.keyCode)
    const pos = input.selectionStart
    if (pos < 3) event.preventDefault()
    const matrix = '+7 (___) ___ ____'
    let i = 0
    const def = matrix.replace(/\D/g, '')
    const val = input.value.replace(/\D/g, '')
    let new_value = matrix.replace(/[_\d]/g, a => {
      return i < val.length ? val.charAt(i++) || def.charAt(i) : a
    })
    i = new_value.indexOf('_')
    if (i != -1) {
      i < 5 && (i = 3)
      new_value = new_value.slice(0, i)
    }
    let reg = matrix
      .substr(0, input.value.length)
      .replace(/_+/g, a => {
        return '\\d{1,' + a.length + '}'
      })
      .replace(/[+()]/g, '\\$&')
    reg = new RegExp('^' + reg + '$')
    if (
      !reg.test(input.value) ||
      input.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    ) {
      input.value = new_value
    }
    if (event.type == 'blur' && input.value.length < 5) {
      input.value = ''
    }
  }

  input.addEventListener('input', mask)
  input.addEventListener('focus', mask)
  input.addEventListener('blur', mask)
  input.addEventListener('keydown', mask)
})
