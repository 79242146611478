const hamburgerButton = document.querySelector('.hamburger-button')

const hamburgerImage = hamburgerButton.querySelector('img')
const defaultHamburgerImage = hamburgerImage.src
const onHamburgerImage = './assets/images/hamburger-off.svg'

hamburgerButton.addEventListener('click', () => {
  const header = document.querySelector('.header')
  header.classList.toggle('is-visible')
  const headerSecond = document.querySelector('.header-second')
  headerSecond.classList.toggle('is-visible')
  const headerFirst = document.querySelector('.header-first')
  headerFirst.classList.toggle('is-visible')
  hamburgerImage.src =
    hamburgerImage.src === defaultHamburgerImage
      ? onHamburgerImage
      : defaultHamburgerImage
})
