const moreHTML = `
<div
  data-index="15"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      16</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/16.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Aтлон FM</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      239
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      12
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Aтлон FM"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    8,1 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="16"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      17</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/17.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">REMONT.FM</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      230
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      11
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="REMONT.FM"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    8,0 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="17"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      18</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/18.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Еврострой Плюс</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      235
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      14
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Еврострой Плюс"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    7,6 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="18"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      19</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/19.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Сити Рем</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      228
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      10
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Сити Рем"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    7,5 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="19"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      20</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/20.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Stella</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      225
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      10
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Stella"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    7,2 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="20"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      21</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/21.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">СтройМастерМ</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      222
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      9
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="СтройМастерМ"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    7,0 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="21"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      22</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/22.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">1000 Ремонтов</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      224
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      15
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="1000 Ремонтов"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    6,8 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="22"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      23</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/23.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Просто ремонт</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      219
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      13
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Просто ремонт"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    6,8 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="23"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      24</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/24.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Мир Уюта</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      218
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      11
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Мир Уюта"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    6,3 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="24"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      25</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/25.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Сказано-Сделано</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      215
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      12
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Сказано-Сделано"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    6,1 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="25"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      26</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/26.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Уровень</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      211
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      13
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Уровень"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    6,0 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="26"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      27</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/27.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Arema-Group</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      214
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      8
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Arema-Group"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    5,9 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="27"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      28</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/28.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Элеон</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      233
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      30
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Элеон"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    5,9 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="28"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      29</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/29.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">Rem Labs</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      190
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      24
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="Rem Labs"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    5,7 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
<div
  data-index="29"
  class="py-[15px] company-row  px-[50px] flex w-full lg:flex-wrap text-[18px] leading-[24px] bg-white transition hover:bg-[#d8dafa] min-h-[80px] relative text-center items-center row border-b border-[#EBEBEB]lg:pr-[10px] md:px-[10px] md:justify-between sm:justify-start sm:gap-y-0"
>
  <p
    class="bg-transparent w-full max-w-[2.5%] top-number bg-[#692EE5] text-white ml-[-50px] my-[-15px] h-[80px] vertical-text relative md:absolute md:max-w-[60px] md:h-auto md:bg-transparent md:text-[#692EE5] md:left-[10px] md:bottom-[15px] md:m-0 md:font-bold md:text-[16px] md:leading-[18px]"
  >
  </p>
  <div
    class="w-full max-w-[15%] h-fit flex ml-[50px] number lg:max-w-[40px] lg:ml-[20px] md:ml-0 md:max-w-[20px] md:h-[20px] md:text-[14px] md:mr-[28px]"
  >
    <p
      class="w-[30px] h-[30px] bg-white rounded flex items-center border justify-center text-[18px] leading-[22px] md:h-[20px] md:text-[14px]"
    >
      30</p
    >
  </div>
  <div
    class="w-full max-w-[26%] relative h-fit flex gap-[10px] items-center company lg:max-w-[32%] sm:items-start sm:max-w-[60%]"
  >
    <div
      class="flex w-[50px] h-[50px] bg-white rounded flex-shrink-0 border border-[#EBEBEB] overflow-hidden"
      ><img
        src="./assets/images/logos/30.png"
        alt="company logo"
        class="w-full h-full object-contain company-image"
    /></div>
    <p class="text-left whitespace-nowrap company-name">СК Новая Москва</p>
  </div>
  <div
    class="w-full max-w-[20%] h-fit flex gap-[30px] reviews sm:absolute md:left-[120px] md:bottom-[15px] sm:top-[45px]"
  >
    <div
      class="flex gap-[2px] items-center text-[#26B00A] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-likes"
    >
      160
      <img
        src="./assets/images/like.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
    <div
      class="flex gap-[2px] items-center text-[#C62E2E] text-[25px] leading-[28px] font-bold sm:text-[16px] sm:leading-[18px] company-dislikes"
    >
      17
      <img
        src="./assets/images/dislike.svg"
        alt="like"
        class="sm:w-[15px]"
      />
    </div>
  </div>
  <p
    class="w-full max-w-[24%] h-fit flex lg:order-8 lg:max-w-[30%] md:ml-auto md:max-w-none sm:border-t sm:pt-[10px] sm:mt-0 md:w-fit sm:w-full sm:ml-[110px]"
  >
    <button
      class="light-button px-[20px] md:whitespace-nowrap sm:ml-0 sm:w-full lg:w-full"
      data-company="СК Новая Москва"
      >Оставить заявку</button
    ></p
  >
  <div
    class="w-full max-w-[12.5%] h-fit flex text-[25px] leading-[28px] total lg:justify-center md:max-w-[30%] sm:text-[16px] sm:leading-[18px] sm:ml-auto sm:w-fit sm:self-end company-rating"
  >
    5,3 <img src="./assets/images/star.svg" class="sm:w-[15px]" alt="*"
  /></div>
</div>
`

const rating = document.querySelector('.rating')

const insertMoreElems = () => {
  rating.insertAdjacentHTML('beforeend', moreHTML)
}

const loadMoreButton = document.querySelector('.rating-overlay')

loadMoreButton.addEventListener('click', () => {
  let tempHeight = rating.clientHeight
  let maxHeight = 2 * tempHeight

  rating.style.maxHeight = tempHeight + 'px'
  insertMoreElems()

  let interval = setInterval(() => {
    tempHeight += 20
    rating.style.maxHeight = tempHeight + 'px'
    if (tempHeight >= maxHeight) clearInterval(interval)
  }, 2)

  loadMoreButton.remove()
})

onresize = () => {
  rating.style.maxHeight = ''
}
